import { Flex } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

interface IDotsSwiperProps {
  slides: ReactNode[];
  containerStyles?: StyleProps;
}

const getColumns = (length: number) => {
  let breakpoints: { [width: number]: SwiperOptions } = {
    0: {
      slidesPerView: 1,
    },
    400: {
      slidesPerView: 1.2,
    },
    596: {
      slidesPerView: 2,
    },
    872: {
      slidesPerView: 3,
    },
    1148: {
      slidesPerView: 4,
    },
  };

  if (length <= 3) {
    delete breakpoints[1148];
  }
  if (length <= 2) {
    delete breakpoints[872];
  }
  if (length <= 1) {
    delete breakpoints[596];
  }

  return breakpoints;
};

const DotsSwiper: FC<IDotsSwiperProps> = ({ slides, containerStyles }) => (
  <Flex
    justifyContent='center'
    sx={{
      '.dots-swiper': {
        w: 'full',
        pb: 'xl',
        userSelect: 'none',
        '.swiper-pagination': {
          bottom: 0,
        },
      },
    }}
    w='full'
    {...containerStyles}
  >
    <Swiper
      breakpoints={getColumns(slides?.length)}
      className='dots-swiper'
      grabCursor={true}
      initialSlide={slides?.length > 2 ? 1 : 0}
      modules={[Pagination]}
      pagination={{
        clickable: true,
      }}
      spaceBetween='20'
    >
      {slides?.map((slide, i) => <SwiperSlide key={i}>{slide}</SwiperSlide>)}
    </Swiper>
  </Flex>
);

export default DotsSwiper;
