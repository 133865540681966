import { Box, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { FC } from 'react';
import { SocialMediaLinks } from '@/widgets/SocialMediaLinks';
import { configModel, footerSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { Layout, Link } from '@/sharedUI';
import { IImageFile, TBlockProps } from '@/sharedLib';
import ColumnWithHeader from './ColumnWithHeader';
import Contacts from './Contacts';
import WorkingHours from './WorkingHours';

const Footer: FC<TBlockProps> = ({ containerStyles }) => {
  const { profileData } = profileClientModel.useProfile();

  const data = configModel.useStore(footerSelector) || {};

  const {
    logo: logoConfig,
    isMenu,
    isContacts,
    isHours,
    companyName,
    menuLinks,
    contactData,
  } = data;

  const logo = logoConfig || (profileData?.profile?.image as IImageFile | undefined);

  return (
    <Layout.Container
      as='footer'
      bg='secondary.base'
      data-testid='footer'
      {...containerStyles}
    >
      <Flex
        alignItems='center'
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        gap={{ base: 'xl', xl: 'xl' }}
        justifyContent='space-between'
        py='xl'
      >
        <Flex
          alignItems={{ base: 'center', lg: 'start' }}
          flexDir={{ base: 'column', md: 'row', lg: 'column' }}
          flexGrow={{ base: 0, xl: 0.8 }}
          gap='xl'
          justifyContent='center'
        >
          <Flex
            alignItems='center'
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
            gap={{ base: '2xs', lg: 'lg' }}
            justifyContent={{ base: 'center', md: 'start', lg: 'center' }}
            w='auto'
          >
            {logo && (
              <Box
                aspectRatio={logo?.aspectRatio || 1}
                h={10}
                maxW='max-content'
                minHeight={10}
                position='relative'
                width='full'
              >
                <Image
                  alt={`logo`}
                  fill
                  loading='eager'
                  objectFit='contain'
                  objectPosition='left'
                  sizes='8rem'
                  src={logo?.rawFile || logo?.url || ''}
                />
              </Box>
            )}
            {companyName && (
              <Text
                color='typography.pure'
                fontSize='xl'
                fontWeight='semibold'
                textTransform='uppercase'
              >
                {companyName}
              </Text>
            )}
          </Flex>
          <SocialMediaLinks
            data={contactData}
            styleLinkProps={{ color: 'typography.pure', h: 6 }}
          />
        </Flex>
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          gap={{ base: 'xl', lg: '4xl' }}
        >
          {isMenu && (
            <ColumnWithHeader
              flexDir={{ base: 'row', lg: 'column' }}
              flexWrap='wrap'
              gap={{ base: 'sm', lg: '4xs' }}
              h='full'
              header='Menu'
              justifyContent={{ base: 'center', lg: 'space-between' }}
            >
              {menuLinks?.map((item: { name: string; link: string }) => (
                <Link
                  key={item.link}
                  color='typography.pure'
                  href={`#${item.link}`}
                  textTransform='capitalize'
                >
                  {item.name}
                </Link>
              ))}
            </ColumnWithHeader>
          )}
          <Flex
            alignItems={{ base: 'center', md: 'start' }}
            flexDir={{ base: 'column', md: 'row' }}
            gap={{ base: 'xl', sm: '3xl' }}
            justifyContent='space-around'
          >
            {isHours && <WorkingHours workingHours={contactData.workingHours} />}
            {isContacts && <Contacts />}
          </Flex>
        </Flex>
      </Flex>
    </Layout.Container>
  );
};

export default Footer;
