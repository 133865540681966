import {
  Button,
  type ContainerProps,
  Heading,
  Highlight,
  VStack,
  useToken,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { FC, useState } from 'react';
import { configModel, coreConfigSelector, mainBlockSelector } from '@/entities/Config';
import { Layout, Modal } from '@/sharedUI';
import { TBlockProps, imageSizes } from '@/sharedLib';
import { ContactUsForm } from '../../contactUsForm';

const EditContainer = dynamic(() =>
  import('src/shared/ui/EditContainer').then((mod) => mod.EditContainer),
);

const styleFormContainer = {
  borderRadius: 'xl',
  p: 'md',
  w: 'full',
};

const TEXT_SHADOW = '0px 0px 6px rgba(0,0,0,0.06), 0px 0px 8px rgba(0,0,0,0.4)';

type TMainBlockProps = {
  textContainerProps?: ContainerProps;
} & TBlockProps;

const MainBlock: FC<TMainBlockProps> = ({ textContainerProps, containerStyles }) => {
  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const {
    bgImage,
    slogan,
    buttonPosition,
    textAlign,
    sepia,
    brightness,
    textSize,
    heightPercentage,
    showButton,
  } = configModel.useStore(mainBlockSelector);

  const filter = `${sepia ? `sepia(${sepia}%)` : ''} ${
    brightness ? `brightness(${brightness}%)` : ''
  }`;

  const vh = useToken('space', 'dvh100');

  const sizes = {
    base: `100%`,
    md: `calc(${parseInt(vh, 10) * (heightPercentage! / 100)}px - 4rem)`,
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout.Container
      alignItems='top'
      bgColor='secondary.base'
      containerBGImage={
        bgImage && {
          image: bgImage,
          alt: 'BG image',
          imgSizes: sizes,
          imageSize: imageSizes.ORIGINAL,
          objectFit: 'cover',
          isEager: true,
          priority: true,
          filter,
        }
      }
      containerProps={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: '3xl',
      }}
      data-testid='main-block'
      height='fit-content'
      minH={`calc(${parseInt(vh, 10) * (heightPercentage! / 100)}px - 4rem)`}
      overflow='hidden'
      {...containerStyles}
    >
      <VStack
        alignItems={buttonPosition || 'center'}
        spacing='2xl'
        textAlign={textAlign || 'center'}
        w='full'
        {...textContainerProps}
      >
        <Heading
          as='h1'
          color='typography.pure'
          maxW='80%'
          size={textSize || '2xl'}
          textShadow={TEXT_SHADOW}
          whiteSpace='pretty'
        >
          <Highlight
            query={slogan?.highlight || ''}
            styles={{ color: 'primary.base', maxW: 'full', whiteSpace: 'pretty' }}
          >
            {slogan?.text || ''}
          </Highlight>
        </Heading>
        {showButton && <Button onClick={() => setIsOpen(true)}>Get a quote</Button>}
      </VStack>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {isEdit && (
          <EditContainer
            containerStyles={{ w: 'full' }}
            isControl={false}
            isIndoor
            name='contactUsForm'
          >
            {(styles) => (
              <ContactUsForm
                containerStyles={{
                  ...styleFormContainer,
                  ...styles,
                }}
                index={null}
              />
            )}
          </EditContainer>
        )}
        {!isEdit && (
          <ContactUsForm
            containerStyles={styleFormContainer}
            index={null}
          />
        )}
      </Modal>
    </Layout.Container>
  );
};

export default MainBlock;
