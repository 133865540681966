import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Card,
  type ContainerProps,
  HStack,
  Heading,
  IconButton,
  Img,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Modal } from '@/sharedUI';
import { TPortfolio, extractImage, imageSizes } from '@/sharedLib';
import PortfolioFullCard from './PortfolioFullCard';

interface PortfolioCardProps extends TPortfolio {
  disableOpen?: boolean;
  isOdd?: boolean;
  styles?: ContainerProps;
}

const PortfolioCard: FC<PortfolioCardProps> = ({
  images,
  title,
  aspectRatio,
  description,
  price,
  isOdd = true,
  disableOpen = false,
  styles,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card
        bg={isOdd ? 'bg.light' : 'bg.pure'}
        border='none'
        gap='sm'
        maxH='lg'
        maxW='md'
        minH='3xs'
        minW='2xs'
        p={0}
        variant='outline'
        w='full'
        {...styles}
      >
        <VStack
          alignItems='flex-start'
          flexGrow={1}
          p='md'
          spacing='md'
        >
          <Heading
            as='h3'
            display='-webkit-box'
            fontWeight='bold'
            h={12}
            overflow='hidden'
            size='md'
            sx={{
              ['WebkitBoxOrient']: 'vertical',
              ['WebkitLineClamp']: 2,
            }}
            textOverflow='ellipsis'
            w='100%'
            wordBreak='normal'
          >
            {title}
          </Heading>

          <HStack
            justifyContent='space-between'
            w='100%'
          >
            <Text
              color='primary.base'
              display='inline'
              fontSize='lg'
            >
              {!!price && `$${price}`}
            </Text>
            {!disableOpen && (
              <IconButton
                aria-label='More'
                borderRadius='full'
                icon={<ArrowForwardIcon boxSize={4} />}
                size='sm'
                variant='outline'
                onClick={onOpen}
              />
            )}
          </HStack>
          <Text flexGrow={1}> {description}</Text>
        </VStack>

        {!!images?.[0] && (
          <Img
            alt={`photo ${images?.[0]?.fileName || ''}`}
            borderBottomRadius='xl'
            h={52}
            objectFit='cover'
            overflow='hidden'
            src={extractImage(images?.[0], imageSizes.M)}
            w='100%'
          />
        )}
      </Card>
      {!disableOpen && (
        <Modal
          header={title}
          isOpen={isOpen}
          showCloseButton
          onClose={onClose}
        >
          <PortfolioFullCard
            description={description}
            images={images}
            price={price}
            title={title}
          />
        </Modal>
      )}
    </>
  );
};

export default PortfolioCard;
