import { Center, Container } from '@chakra-ui/react';
import type { CenterProps, ContainerProps, FlexProps } from '@chakra-ui/react';
import { ForwardRefRenderFunction, forwardRef } from 'react';
import { BreadcrumbType } from '@/sharedLib';
import Image, { ImageProps } from '../../Image';
import Breadcrumbs from './Breadcrumbs';

interface LayoutContainerProps extends CenterProps {
  breadcrumbs?: BreadcrumbType[];
  withPadding?: boolean;
  containerProps?: ContainerProps | FlexProps;
  containerBGImage?: ImageProps;
  isOdd?: boolean;
}

const LayoutContainer: ForwardRefRenderFunction<HTMLDivElement, LayoutContainerProps> = (
  {
    children,
    isOdd = true,
    breadcrumbs,
    containerBGImage,
    withPadding = true,
    containerProps = {},
    ...restProps
  },
  ref,
) => (
  <Center
    ref={ref}
    as='section'
    bgColor={isOdd ? 'bg.pure' : 'bg.light'}
    position='relative'
    width='full'
    {...restProps}
  >
    <Container
      maxW='container.xl'
      px={withPadding ? ['sm', 'xl'] : 0}
      zIndex={1}
      {...containerProps}
    >
      {breadcrumbs && <Breadcrumbs chain={breadcrumbs} />}
      {children}
    </Container>

    {!!containerBGImage?.image && (
      <Image
        position='absolute'
        width='full'
        {...containerBGImage}
      />
    )}
  </Center>
);

export default forwardRef(LayoutContainer);
