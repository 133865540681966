import { Box, Highlight, Text } from '@chakra-ui/react';
import { FC, useId } from 'react';
import { useForm } from 'react-hook-form';
import { configModel, settingsSelector } from '@/entities/Config';
import { privateConfigModel } from '@/entities/PrivateConfig';
import {
  CheckboxField,
  EmailField,
  Form,
  FormControls,
  InputField,
  PhoneField,
  TextareaField,
} from '@/sharedUI';
import { REQUIRED_FIELD_MSG, TBlockProps } from '@/sharedLib';

type ContactUsFormProps = TBlockProps & {
  headingFormText?: string;
};

const ContactUsForm: FC<ContactUsFormProps> = ({ isOdd = true, containerStyles }) => {
  const formId = useId();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      isConsent: false,
    },
  });

  const { sendMessage, loading } = privateConfigModel.useSendContactFormMessage();
  const { companyName } = configModel.useStore(settingsSelector);

  const CONSENT_TEXT = `By checking this box, I consent to receive marketing communications, via SMS and email from ${companyName}`;
  const isConsent = methods.watch('isConsent');

  const onSubmit = (data: any) =>
    sendMessage({
      email: data?.email,
      phone: data?.phone,
      name: data?.name,
      description: data?.message,
    });

  return (
    <Box
      bg={isOdd ? 'bg.light' : 'bg.pure'}
      position='relative'
      {...containerStyles}
    >
      <Form
        formId={formId}
        methods={methods}
        onSubmit={onSubmit}
      >
        <InputField
          bg='bg.pure'
          name='name'
          placeholder='Name'
          validationParameters={{
            required: REQUIRED_FIELD_MSG,
          }}
        />
        <EmailField />
        <PhoneField withoutLabel />
        <TextareaField
          bg='bg.pure'
          name='message'
          placeholder='Message'
          validationParameters={{
            required: REQUIRED_FIELD_MSG,
          }}
        />
        <CheckboxField
          Label={
            <Text>
              <Highlight
                query={[companyName || '']}
                styles={{ color: 'primary.base' }}
              >
                {CONSENT_TEXT}
              </Highlight>
            </Text>
          }
          name='isConsent'
        />
        <FormControls
          formId={formId}
          isLoading={loading}
          isPositiveDisabled={!isConsent}
          positiveButtonStyle={{ width: '100%' }}
          positiveText='Send'
        />
      </Form>
    </Box>
  );
};

export default ContactUsForm;
