import { gql } from '@urql/core';

const SEND_CONFIRM_EMAIL = gql`
  mutation sendConfirmLandingContactFormEmail {
    sendConfirmLandingContactFormEmail {
      email
      status
    }
  }
`;

const CONFIRM_EMAIL = gql`
  mutation confirmLandingContactFormEmail($input: ConfirmEmailInput!) {
    confirmLandingContactFormEmail(input: $input) {
      email
      status
    }
  }
`;

const UPDATE_CONTACT_FORM_EMAIL = gql`
  mutation updateLandingContactFormEmail($input: UpdateEmailInput) {
    updateLandingContactFormEmail(input: $input) {
      email
      status
    }
  }
`;

const SEND_CONTACT_FORM_MESSAGE = gql`
  mutation sendLandingContactFormMessage($input: SendLandingContactFormMessageInput) {
    sendLandingContactFormMessage(input: $input) {
      description
    }
  }
`;

const LANDING_PRIVATE_CONFIG = gql`
  query landingPrivateConfig {
    landingPrivateConfig {
      email {
        email
        status
      }
      tenantId
    }
  }
`;

export {
  SEND_CONFIRM_EMAIL,
  CONFIRM_EMAIL,
  LANDING_PRIVATE_CONFIG,
  UPDATE_CONTACT_FORM_EMAIL,
  SEND_CONTACT_FORM_MESSAGE,
};
