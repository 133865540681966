import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Card,
  type ContainerProps,
  Divider,
  HStack,
  Heading,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Modal } from '@/sharedUI';
import { TServiceCard } from '@/sharedLib';
import ServicesFullCard from './ServicesFullCard';

interface IServicesCard extends TServiceCard {
  isOdd?: boolean;
  disableOpen?: boolean;
  styles?: ContainerProps;
}

const ServicesCard: FC<IServicesCard> = ({
  header,
  highlight,
  description,
  price,
  isOdd = true,
  disableOpen = false,
  styles,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card
        bg={isOdd ? 'bg.light' : 'bg.pure'}
        border='none'
        gap='sm'
        maxH='lg'
        maxW='md'
        minH='3xs'
        minW='2xs'
        p='sm'
        variant='outline'
        w='full'
        {...styles}
      >
        <Box>
          <Heading
            as='h3'
            display='-webkit-box'
            fontWeight='bold'
            minH={8}
            overflow='hidden'
            size='md'
            sx={{
              ['WebkitBoxOrient']: 'vertical',
              ['WebkitLineClamp']: 2,
            }}
            textOverflow='ellipsis'
            w='100%'
            wordBreak='normal'
          >
            {header}
          </Heading>

          {highlight ? (
            <Divider
              borderColor='primary.base'
              borderWidth='2px'
              width='60%'
            />
          ) : (
            <Box h='2px' />
          )}
        </Box>
        <HStack
          justifyContent='space-between'
          w='100%'
        >
          {price?.[0] && (
            <Box>
              <Text
                color='primary.base'
                display='inline'
                fontSize='md'
                mr='xs'
              >
                {price[0].price}
              </Text>
              <Text
                color='primary.medium'
                display='inline'
                fontSize='md'
              >
                {price[0].unit}
              </Text>
            </Box>
          )}

          {!disableOpen && (
            <IconButton
              aria-label='More'
              borderRadius='full'
              icon={<ArrowForwardIcon boxSize={4} />}
              size='sm'
              variant='outline'
              onClick={onOpen}
            />
          )}
        </HStack>
        <Text
          fontSize='lg'
          fontWeight='medium'
          noOfLines={3}
          textAlign='justify'
        >
          {description}
        </Text>
      </Card>
      {!disableOpen && (
        <Modal
          header={header}
          isOpen={isOpen}
          showCloseButton
          onClose={onClose}
        >
          <ServicesFullCard
            description={description}
            highlight={highlight}
            price={price}
          />
        </Modal>
      )}
    </>
  );
};

export default ServicesCard;
