import { Link as NextLink } from '@chakra-ui/next-js';
import type { LinkProps } from '@chakra-ui/next-js';
import { ForwardRefRenderFunction, forwardRef } from 'react';

const Link: ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> = (
  { children, ...props },
  ref,
) => (
  <NextLink
    ref={ref}
    prefetch={false}
    {...props}
  >
    {children}
  </NextLink>
);

export default forwardRef(Link);
