import { Checkbox, FormControl } from '@chakra-ui/react';
import type { CheckboxProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import FormFieldMeta from './FormFieldMeta';

interface CheckboxFieldProps extends CheckboxProps {
  name: string;
  validation?: Record<string, unknown>;
  isRequired?: boolean;
  label?: string;
  helperText?: string;
  Label?: ReactNode;
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  name,
  isRequired = false,
  helperText,
  label = '',
  Label,
  ...rest
}) => {
  const { register } = useFormContext();
  const { fieldState } = useController({
    name,
  });
  const { invalid, error } = fieldState;

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
      w='full'
    >
      <Checkbox
        gap='xs'
        {...register(name)}
        {...rest}
      >
        {Label ? Label : label}
      </Checkbox>
      <FormFieldMeta
        errors={{ [name]: error }}
        helperText={helperText}
        name={name}
      />
    </FormControl>
  );
};

export default CheckboxField;
