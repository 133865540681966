import { HStack } from '@chakra-ui/react';
import type { ContainerProps, LayoutProps, StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { StarFilled, StarHalfIcon } from '../icons';

interface RatingProps extends StackProps {
  rating: 0 | 1 | 2 | 3 | 4 | 5;
  size?: LayoutProps['height'];
  styles?: ContainerProps;
}

const Rating: FC<RatingProps> = ({ rating, size = 6, styles }) => {
  const stars = new Array(5).fill('');

  return (
    <HStack
      borderRadius='xl'
      flexWrap='nowrap'
      px={1.5}
      py={2}
      spacing='3xs'
      zIndex={1}
      {...styles}
    >
      {stars.map((_, index) => {
        const ratingPart = rating - Math.floor(rating);
        const isPositiveStar =
          index + 1 <= rating || (index === Math.floor(rating) && ratingPart >= 0.8);
        const isHalfStar = index === Math.floor(rating) && ratingPart >= 0.2 && ratingPart < 0.8;

        if (isHalfStar) {
          return (
            <StarHalfIcon
              key={index}
              boxSize={size}
              fill='star.positive'
            />
          );
        }
        return (
          <StarFilled
            key={index}
            boxSize={size}
            fill={isPositiveStar ? 'star.positive' : 'star.negative'}
          />
        );
      })}
    </HStack>
  );
};

export default Rating;
