import type { FieldPath, FieldValues } from 'react-hook-form';
import { REQUIRED_FIELD_MSG, emailRegExp } from '@/sharedLib';
import InputField, { TInputFieldProps } from './InputField';

type EmailFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TInputFieldProps<TFieldValues, TName>, 'validationParameters' | 'name' | 'placeholder'> &
  Partial<Pick<TInputFieldProps<TFieldValues, TName>, 'name' | 'placeholder'>> & {
    isRequired?: boolean;
  };

const EmailField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label = '',
  name,
  placeholder = 'E-mail',
  isRequired,
  ...restProps
}: EmailFieldProps<TFieldValues, TName>) => (
  <InputField
    label={label}
    name={name || ('email' as TName)}
    placeholder={placeholder}
    validationParameters={{
      required: isRequired ? REQUIRED_FIELD_MSG : undefined,
      pattern: {
        value: emailRegExp,
        message: 'Wrong email address',
      },
    }}
    {...restProps}
  />
);

export default EmailField;
