import { TimeIcon } from '@chakra-ui/icons';
import { Heading, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { SocialMediaLinks } from '@/widgets/SocialMediaLinks';
import { ContactUsForm } from '@/widgets/contactUsForm';
import { configModel, coreConfigSelector, personalDataSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { ContactLinkItem, Layout, MailIcon, MapPinIcon, PhoneIcon } from '@/sharedUI';
import { TBlockProps, phoneFormat } from '@/sharedLib';

const EditContainer = dynamic(() =>
  import('src/shared/ui/EditContainer').then((mod) => mod.EditContainer),
);

const styleFormContainer = {
  borderRadius: 'xl',
  p: 'md',
  w: 'full',
};

const styleDataContainer = {
  fontSize: { base: 'md', sm: 'md' },
  fontWeight: 'normal',
  gap: 'md',
  justifyContent: 'flex-start',
  width: 'full',
};

const styleWorkingHoursContainer = {
  justify: 'start',
  spacing: 'sm',
  w: '100%',
};

const ContactsBlock: FC<TBlockProps> = ({ isOdd, containerStyles }) => {
  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const data = configModel.useStore(personalDataSelector) || {};

  const {
    address,
    googleMapsUrl,
    email,
    phone: tel,
    workingHours,
    fbLink,
    instagramLink,
    telegramLink,
    xLink,
  } = data;

  const { profileData } = profileClientModel.useProfile();

  const phone = tel || profileData?.phone;

  return (
    <>
      <Layout.Container
        containerProps={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: { base: 'column', md: 'row' },
          gap: 'xl',
          width: 'full',
        }}
        data-testid='contacts-block'
        id='contacts'
        isOdd={isOdd}
        py='3xl'
        {...containerStyles}
      >
        <VStack
          {...styleDataContainer}
          position='relative'
        >
          <Heading
            as='h2'
            variant='homeBlock'
          >
            Contact us
          </Heading>
          {phone && (
            <ContactLinkItem
              dataGtm='phone-footer'
              href={`tel:${phone}`}
              icon={<PhoneIcon />}
              label={phoneFormat(phone)}
            />
          )}
          {email && (
            <ContactLinkItem
              dataGtm='mail-footer'
              href={`mailto:${email}`}
              icon={<MailIcon />}
              label={email}
            />
          )}
          {address && googleMapsUrl && (
            <ContactLinkItem
              dataGtm='location-footer'
              href={googleMapsUrl}
              icon={<MapPinIcon />}
              label={address}
            />
          )}
          {workingHours && (
            <Stack
              direction='row'
              {...styleWorkingHoursContainer}
            >
              <Icon
                as={TimeIcon}
                mt={1}
              />
              <Text whiteSpace='pre'>{workingHours}</Text>
            </Stack>
          )}
          <SocialMediaLinks
            containerStyles={{
              alignSelf: { base: 'center', md: 'flex-start' },
              ml: { base: 0, md: 'xl' },
            }}
            data={{
              fbLink,
              instagramLink,
              telegramLink,
              xLink,
            }}
          />
        </VStack>
        {isEdit ? (
          <EditContainer
            containerStyles={{ w: 'full' }}
            isControl={false}
            isIndoor
            name='contactUsForm'
          >
            {(styles) => (
              <ContactUsForm
                containerStyles={{
                  ...styleFormContainer,
                  ...styles,
                }}
                index={null}
                isOdd={isOdd}
              />
            )}
          </EditContainer>
        ) : (
          <ContactUsForm
            containerStyles={styleFormContainer}
            index={null}
            isOdd={isOdd}
          />
        )}
      </Layout.Container>
    </>
  );
};

export default ContactsBlock;
