import Head from 'next/head';
import { configModel, coreConfigSelector, metaDecoratorSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { config, imageSizes } from '@/sharedLib';
import { extractImage, sliceTextByLength } from '../helpers';

const defaultImage = '/images/default-snippet.png';

const MetaDecorator = () => {
  const { subdomain } = configModel.useGetStoreData(coreConfigSelector);

  const { profileData } = profileClientModel.useProfile();
  const {
    logo: logoConfig,
    colors,
    bgImage,
    companyName,
  } = configModel.useStore(metaDecoratorSelector);

  const favIcon = logoConfig || profileData?.profile?.image;
  const metaDescription = sliceTextByLength(profileData?.profile?.description, 155);
  const SITE_NAME = `${subdomain}.${config.domain}`;
  const url = config.isProd
    ? `${config.protocol}${SITE_NAME}`
    : `${config.protocol}${config.domain}/landings/${subdomain}`;

  return (
    <Head>
      <title>{companyName || subdomain}</title>
      <link
        href={favIcon ? extractImage(favIcon, imageSizes.XS) : '/icons/default-favicon.png'}
        rel='icon'
        sizes='any'
      />
      <meta
        key='description'
        content={metaDescription}
        name='description'
      />
      <meta
        key='application-name'
        content={SITE_NAME}
        name='application-name'
      />
      <meta
        key='theme-color'
        content={colors?.primary}
        name='theme-color'
      />
      <meta
        key='og:title'
        content={companyName}
        property='og:title'
      />
      <meta
        key='og:description'
        content={metaDescription}
        property='og:description'
      />
      <meta
        key='og:site_name'
        content={SITE_NAME}
        property='og:site_name'
      />
      <meta
        key='og:url'
        content={url}
        property='og:url'
      />
      <meta
        key='og:type'
        content='website'
        property='og:type'
      />
      <meta
        key='og:image'
        content={bgImage?.url || defaultImage}
        property='og:image'
      />
      <meta
        key='twitter:title'
        content={companyName}
        name='twitter:title'
      />
      <meta
        key='twitter:description'
        content={metaDescription}
        name='twitter:description'
      />
      <meta
        key='twitter:url'
        content={url}
        name='twitter:url'
      />
      <meta
        key='twitter:image:src'
        content={bgImage?.url || defaultImage}
        name='twitter:image:src'
      />
      <meta
        key='twitter:card'
        content='summary_large_image'
        name='twitter:card'
      />
    </Head>
  );
};

export default MetaDecorator;
