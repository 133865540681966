import { FormControl, NumberInput, NumberInputField } from '@chakra-ui/react';
import type { ContainerProps, NumberInputProps } from '@chakra-ui/react';
import type { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useController } from 'react-hook-form';
import {
  PHONE_VALIDATION_MSG,
  REQUIRED_FIELD_MSG,
  phoneFormat,
  phoneParse,
  phoneRegExp,
  symbolInPhoneRegExp,
} from '@/sharedLib';
import FormFieldMeta from './FormFieldMeta';
import LabelForm from './LabelForm';

type PhoneFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<NumberInputProps, 'name'> & {
  control?: Control<TFieldValues>;
  name?: TName;
  containerProps?: ContainerProps;
  requiredErrorText?: string;
  label?: string;
  withoutLabel?: boolean;
};

const PhoneField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  withoutLabel,
  control,
  name = 'phone' as TName,
  onChange,
  onBlur,
  containerProps,
  isRequired,
  requiredErrorText,
  label = 'Phone',
  ...rest
}: PhoneFieldProps<TFieldValues, TName>) => {
  const {
    fieldState,
    field: { onChange: onFieldChange, onBlur: onFieldBlur, value },
  } = useController({
    control,
    name,
    rules: {
      required: isRequired && (requiredErrorText || REQUIRED_FIELD_MSG),
      pattern: {
        value: new RegExp(phoneRegExp),
        message: PHONE_VALIDATION_MSG,
      },
    },
  });
  const { invalid, error } = fieldState;

  const handleChange: NumberInputProps['onChange'] = (stringValue, numberValue) => {
    if (stringValue?.length > 14) return;
    onFieldChange(stringValue);
    onChange?.(stringValue.replace(symbolInPhoneRegExp, ''), numberValue);
  };

  const handleBlur: NumberInputProps['onBlur'] = (e) => {
    onFieldBlur();
    onBlur?.(e);
  };

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
      {...containerProps}
    >
      {!withoutLabel && <LabelForm label={label} />}

      <NumberInput
        inputMode='tel'
        pattern={phoneRegExp}
        value={phoneFormat(phoneParse(value)) || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        {...rest}
      >
        <NumberInputField
          id='phone'
          placeholder='Phone'
        />
      </NumberInput>

      <FormFieldMeta
        errors={{ [name]: error }}
        name={name}
      />
    </FormControl>
  );
};

export default PhoneField;
