import { Icon, Stack, Text } from '@chakra-ui/react';
import type { ColorProps, IconProps, TextProps } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { Link } from '@/sharedUI';

interface ContactLinkItemProps {
  dataGtm?: string;
  fontSize?: TextProps['fontSize'];
  fontWeight?: TextProps['fontWeight'];
  href: string;
  icon: ReactElement;
  iconSize?: IconProps['height'];
  iconWh?: IconProps['height'];
  label: string;
  color?: ColorProps['color'];
  targetBlank?: boolean;
  align?: 'start' | 'end' | 'center';
}

const ContactLinkItem: FC<ContactLinkItemProps> = ({
  dataGtm = '',
  href,
  fontSize,
  fontWeight,
  icon = 6,
  iconSize,
  label,
  color,
  targetBlank = false,
  align = 'start',
}) => {
  return (
    <Link
      color={color}
      data-gtm={dataGtm}
      fontWeight='normal'
      href={href}
      target={targetBlank ? '_blank' : undefined}
      w='full'
      onClick={() => {
        window?.dataLayer?.push({ event: dataGtm });
      }}
    >
      <Stack
        align='center'
        direction={align === 'end' ? 'row-reverse' : 'row'}
        justify={align}
        spacing='sm'
      >
        <Icon
          height={iconSize}
          width={iconSize}
        >
          {icon}
        </Icon>
        <Text
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {label}
        </Text>
      </Stack>
    </Link>
  );
};

export default ContactLinkItem;
