import type { ContainerProps, TextareaProps } from '@chakra-ui/react';
import { FormControl, Textarea } from '@chakra-ui/react';
import type { Control, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { REQUIRED_FIELD_MSG } from '../../../lib';
import FormFieldMeta from './FormFieldMeta';
import LabelForm from './LabelForm';

type TextareaFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextareaProps & {
  control?: Control<TFieldValues>;
  name: TName;
  validationParameters?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
  label?: string;
  helperText?: string;
  containerProps?: ContainerProps;
};

const TextareaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  validationParameters = {},
  helperText,
  isRequired,
  label,
  onChange,
  onBlur,
  containerProps,
  ...rest
}: TextareaFieldProps<TFieldValues, TName>) => {
  const {
    fieldState: { invalid, error },
    field: { onChange: onFieldChange, onBlur: onFieldBlur, ...restFiledProps },
  } = useController({
    name,
    control,
    rules: isRequired
      ? {
          required: REQUIRED_FIELD_MSG,
          ...validationParameters,
        }
      : validationParameters,
  });

  const handleChange: TextareaProps['onChange'] = (event) => {
    onFieldChange(event);
    onChange?.(event);
  };

  const handleBlur: TextareaProps['onBlur'] = (event) => {
    onFieldBlur();
    onBlur?.(event);
  };

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
      {...containerProps}
    >
      {label && <LabelForm label={label} />}
      <Textarea
        onBlur={handleBlur}
        onChange={handleChange}
        {...rest}
        {...restFiledProps}
      />
      <FormFieldMeta
        errors={{ [name]: error }}
        helperText={helperText}
        name={name}
      />
    </FormControl>
  );
};

export default TextareaField;
