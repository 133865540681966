import { Modal, ModalBody, ModalCloseButton, ModalContent, useMediaQuery } from '@chakra-ui/react';
import { CSSProperties, FC } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Keyboard, Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IImageFile, imageSizes } from '../../lib';
import Image from '../Image';
import ModalOverlay from '../ModalOverlay';

const modalContentStyles = {
  bg: 'blackTransparent.500',
  borderRadius: 'none',
  my: 0,
  pt: 14,
  sx: {
    '--swiper-navigation-color': '#fff',
  },
};

const modalBodyStyles = {
  h: '100%',
  display: 'flex',
  p: 0,
  sx: {
    '.mySwiper': {
      _hover: {
        '.swiper-button-prev,.swiper-button-next': {
          opacity: 0.8,
          _hover: {
            opacity: 1,
            transform: 'translateY(-50%) scale(1.1)',
          },
        },
      },
    },
    '.swiper-slide': {
      padding: ['0 0', '0 0', '0 50px'],
    },
    '.swiper-pagination': {
      top: 0,
      bottom: 'calc(100vh - 50px - 32px)',
      color: 'typography.pure',
    },
    '.swiper-button-prev,.swiper-button-next': {
      top: '50%',
      transform: 'translateY(-50%)',
      mt: 0,
      color: 'typography.pure',
      display: 'flex',
      transition: 'all 0.2s',
    },
    '.swiper-button-prev:after,.swiper-button-next:after': {
      fontWeight: 'bold',
    },
    '.swiper-zoom-container': {
      position: 'absolute',
      top: '32px',
      h: 'calc(100% - 40px)',
      w: { base: 'full', md: 'calc(100% - 100px)' },
    },
  },
};

const imgStyles = {
  h: 'full',
  w: 'full',
  mx: 'auto',
  my: 'auto',
};

const SwiperSlider: FC<{
  images: IImageFile[];
  initImageIndex?: number;
  isOpen: boolean;
  unoptimizeImages?: boolean;
  setOpen: (isOpen: boolean) => void;
}> = ({ images, isOpen, setOpen, initImageIndex = 0, unoptimizeImages }) => {
  const isSinglePhoto = images?.length > 1;
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Modal
      isOpen={isOpen}
      size='full'
      onClose={() => setOpen(false)}
    >
      <ModalOverlay />
      <ModalContent {...modalContentStyles}>
        <ModalCloseButton
          color='typography.pure'
          size='lg'
          variant='outline'
          zIndex='20'
        />
        <ModalBody {...modalBodyStyles}>
          <Swiper
            className='mySwiper'
            initialSlide={initImageIndex}
            loop={!!isSinglePhoto}
            modules={[Zoom, Navigation, Pagination, Keyboard]}
            navigation={!isMobile && images?.length > 1}
            pagination={{
              type: 'fraction',
            }}
            spaceBetween={10}
            style={
              {
                width: '100%',
                maxWidth: '96rem',
                '--swiper-navigation-color': '#fff',
              } as CSSProperties
            }
            zoom
          >
            {images?.map((image, index) => (
              <SwiperSlide key={image.id || index}>
                {({ isActive, isNext }) => (
                  <div className='swiper-zoom-container'>
                    <Image
                      alt={'photo-' + image.fileName}
                      image={image}
                      imageSize={imageSizes.ORIGINAL}
                      imgSizes={{
                        base: '100vw',
                        md: 'calc(100vw - 100px)',
                        '2xl': 'calc(90rem - 100px)',
                      }}
                      isEager={isActive || isNext}
                      objectFit='contain'
                      unoptimized={unoptimizeImages}
                      {...imgStyles}
                    />
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SwiperSlider;
